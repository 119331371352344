import { useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Grid, Link, Typography } from "@mui/material";
import { ConstructionOutlined, ExitToApp as LoginIcon } from "@mui/icons-material";
import { useUserContext } from "@fenix/core/context";

import {
  FormPasswordField,
  FormTextField,
} from "@fenix/core/controls/FormTypes";

import { onInvalidForm } from "@fenix/core/helpers/tools";
import LoadingButton from "@mui/lab/LoadingButton";
import { authenticate } from "./security.api";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const form = useForm({ shouldFocusError: true });
  const navigate = useNavigate();
  const { startAuthorization } = useUserContext();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const result = await authenticate(data.email, data.password);
      if (!result.error) {
        await startAuthorization(result);
        navigate('/');
      }
    } catch { }
    finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Typography component="h3" variant="h5" sx={{ m: "20px 0px" }}>
        {"Logowanie"}
      </Typography>
      <form
        onSubmit={form.handleSubmit(onSubmit, () => onInvalidForm())}
        noValidate
      >
        <FormTextField
          form={form}
          label={"Email"}
          required={true}
          lowercase
          name={"email"}
          key={"email"}
          trim={true}
        // rules={{
        //   required: t("Login:ruleEmail"),
        //   pattern: {
        //     value: emailPattern,
        //     message: t("Login:ruleIncorrectEmail"),
        //   },
        // }}
        />
        <FormPasswordField
          form={form}
          label={"Hasło"}
          required={true}
          name={"password"}
          rules={{ required: "Podaj hasło" }}
          key={"password"}
        />
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<LoginIcon />}
          id="login-submit-btn"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          {"Zaloguj"}
        </LoadingButton>
        <Grid container>
          <Grid item xs>
            <Link
              href="/security/reset-init"
              variant="body2"
              id="login-forgotPassword"
            >
              {"Zapomniałem/am hasła"}
            </Link>
          </Grid>
        </Grid>
      </form>
      <br />
    </Container>
  );
}
