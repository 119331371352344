import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Grid, Link, Typography } from "@mui/material";
import {
  ConstructionOutlined,
  ExitToApp as LoginIcon,
} from "@mui/icons-material";
import { useUserContext } from "@fenix/core/context";
import { useSnackbar } from "notistack";

import {
  FormPasswordField,
  FormTextField,
  FormCheckbox,
} from "@fenix/core/controls/FormTypes";
import Stack from "@mui/material/Stack";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { onInvalidForm } from "@fenix/core/helpers/tools";
import LoadingButton from "@mui/lab/LoadingButton";
import { activateAccount, verifyChangePasswordToken } from "./security.api";
import useStyles from "./style";
import queryString from "query-string";


import { useNavigate } from "react-router-dom";

export default function ActivateAccount() {
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const form = useForm({ shouldFocusError: true });
  const navigate = useNavigate();
  const classes = useStyles();
  const [queryParameters] = useState(queryString.parse(location.search));
  const { userContext } = useUserContext();
  const [tokenValid, setTokenValid] = useState(null);
  const { handleSubmit, getValues } = form;
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    setLoading(true);
    (async () => {
      const result = await verifyChangePasswordToken(
        queryParameters.userId,
        queryParameters.code
      );
      setTokenValid(result);
      setLoading(false);
    })();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const result = await activateAccount({
        firstName: data.firstname,
        lastName: data.lastname,
        password: data.password1,
        policyAccepted: data.policyaccepted,
        userId: queryParameters.userId,
        operationToken: queryParameters.code,
      });

      enqueueSnackbar("Konto zostało aktywowane. Teraz możesz się zalogować", { variant: "success" });
      navigate("/");
    } catch {
    } finally {
      setLoading(false);
    }
  };

  if (tokenValid === false || !!userContext || !queryParameters.code || !queryParameters.userId) {
    return (
      <Stack direction="row" alignItems="center" gap={1} justifyContent="center">
        <ErrorOutlineIcon color="error"></ErrorOutlineIcon>
        <Typography variant="h5" color="error">
          Link aktywacyjny jest został już wykorzystany lub jest niepoprawny
        </Typography>
      </Stack>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <Typography component="h3" variant="h5" sx={{ m: "20px 0px" }}>
        {"Aktywacja konta"}
      </Typography>
      <form
        autoComplete="off"
        onSubmit={form.handleSubmit(onSubmit, (t) => onInvalidForm(t))}
        noValidate
      >
        <Grid container direction="column" spacing={2}>
          <FormTextField
            autoComplete="new-password"
            form={form}
            label="Imię"
            name="firstname"
            required={true}
            rules={{ required: "Podaj Imię" }}
          />

          <FormTextField
            autoComplete="new-password"
            form={form}
            label="Nazwisko"
            required={true}
            rules={{ required: "Podaj Nazwisko" }}
            name="lastname"
          />

          <FormPasswordField
            autocomplete="new-password"
            form={form}
            label={"Hasło"}
            required={true}
            name={"password1"}
            rules={{
              required: "Podaj hasło",
            }}
            key={"password1"}
          />

          <FormPasswordField
            form={form}
            label={"Powtórz hasło"}
            required={true}
            name={"password2"}
            rules={{
              required: "Podaj hasło ponownie",
              validate: (value) =>
                value === getValues("password1") ||
                "Hasła muszą być takie same",
            }}
            key={"password2"}
          />

          <FormCheckbox
            label="Oświadczam, że zapoznałem się z obowiązującym regulaminem, warunkami korzystania z serwisu i polityką prywatności"
            description={
              <Typography variant="body2">
                Oświadczam, że zapoznałem się z obowiązującym 
                <Link href="/"> regulaminem</Link>, 
                <Link href="/"> warunkami korzystania z serwisu</Link>  i 
                <Link href="/"> polityką prywatności</Link>
              </Typography>
            }
            form={form}
            onChanged={(checked) => setSubmitDisabled(!checked)}
            name="policyaccepted"
            required={true}
            rules={{ validate: (value) => value !== true ? "Proszę zaznaczyć oświadczenie" : null}}
          ></FormCheckbox>

      <LoadingButton
          sx={{marginTop: 4}}
          disabled={submitDisabled}
          loading={loading}
          id="passwordResetProceed-submit-btn"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Zapisz
        </LoadingButton>
        
        </Grid>
        
      </form>
      <br />
    </Container>
  );
}
