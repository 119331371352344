import React, { createContext, useEffect, useState } from "react";
import { setAxiosClientAuthHeaders } from "@fenix/core/services/axios";
import { authorize } from "./security.api";

const UserContext = createContext();
UserContext.displayName = "UserContext";

export const UserContextProvider = ({ children, withSession = false, type = "localStorage", useContext = true }) => {
  const storage = type === "sessionStorage" ? sessionStorage : localStorage;

  const [token, setToken] = useState(null);

  useEffect(() => {
    if (!useContext) {
      return;
    }
    let url = window.location.search;
    if (url.charAt(0) === "?") url = url.slice(1);
    const urlParams = url.split("&");
    let tmpToken = null;
    let tmpBackUrl = null;

    for (let i = 0; i < urlParams.length; i++) {
      let param = urlParams[i].split("=");
      if (param[0] === "token") {
        setToken(param[1]);
        tmpToken = param[1];
      } else if (param[0] === "backUrl") {
        tmpBackUrl = param[1];
      }
    }

    if (tmpToken !== null) startAuthorization(tmpToken, tmpBackUrl);
  }, []);

  const startAuthorization = async (token, backUrl = null) => {
    setToken(token);
    setAxiosClientAuthHeaders(token);
    let data = await authorize();

    if (!data.error) {
      data.token = token;
      data.backUrl = backUrl;
      saveUserContext(data);
    }
  };

  const getUserContext = () => {
    if (withSession) {
      if (window.location.pathname === "/security/reset-init" || window.location.pathname === "/security/reset-proceed") {
        console.log("resetinit");
        return undefined;
      }

      const context = storage.getItem("context");
      if (context != null && context !== "undefined") {
        const parsedContext = JSON.parse(context);
        setAxiosClientAuthHeaders(parsedContext.token);
        return parsedContext;
      } else {
        return undefined;
      }
    }
  };

  const [userContext, setUserContext] = useState(getUserContext());

  const saveUserContext = (userContext) => {
    withSession && storage.setItem("context", JSON.stringify(userContext));
    setUserContext(userContext);
  };

  const clearUserContext = () => {
    console.log("clearUserContext");
    storage.removeItem("context");
    setUserContext(undefined);

    setAxiosClientAuthHeaders(null);
  };

  useEffect(() => {
    if (userContext !== undefined && userContext?.token === undefined) {
      let data = userContext;
      userContext.token = token;
      saveUserContext(data);
    }
    setAxiosClientAuthHeaders(userContext?.token);
  }, [userContext]);

  return (
    <UserContext.Provider
      value={{
        setUserContext: saveUserContext,
        userContext,
        clearUserContext,
        startAuthorization,
        token,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => React.useContext(UserContext);

export default useUserContext;
