import { createTheme } from "@mui/material/styles";

// mui localization
import "@fontsource/poppins";
import "@fontsource/roboto";
import { plPL as gridPL } from "@mui/x-data-grid-pro";

// A custom theme for this app
export const light = createTheme(
  {
    spacing: 4,
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
    },
    values: {
      up: "f d()",
      down: "f down()",
      between: "f p()",
      only: "f only()",
      width: "f width()",
    },

    typography: {
      htmlFontSize: 14,
      fontFamily: "Poppins, Arial",
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },

    palette: {
      mode: "light",
      // common: {
      //   black: "#222222",
      //   white: "#fff"
      // },
      // type: "light",
      // primary: {
      //   light: "#7986cb",
      //   main: "#18276a",
      //   dark: "#0098d8",
      //   contrastText: "#fff"
      // },
      // secondary: {
      //   light: "#ff4081",
      //   main: "#393E42",
      //   dark: "#0098d8",
      //   contrastText: "#fff"
      // },
      // error: {
      //   light: "#e57373",
      //   main: "#f44336",
      //   dark: "#d32f2f",
      //   contrastText: "#fff"
      // },
      // warning: {
      //   light: "#ffb74d",
      //   main: "#ff9800",
      //   dark: "#f57c00",
      //   contrastText: "rgba(0, 0, 0, 0.87)"
      // },
      // info: {
      //   light: "#64b5f6",
      //   main: "#2196f3",
      //   dark: "#1976d2",
      //   contrastText: "#fff"
      // },
      // success: {
      //   light: "#81c784",
      //   main: "#4caf50",
      //   dark: "#388e3c",
      //   contrastText: "rgba(0, 0, 0, 0.87)"
      // },
      // grey: {
      //   50: "#fafafa",
      //   100: "#f5f5f5",
      //   200: "#eeeeee",
      //   300: "#e0e0e0",
      //   400: "#bdbdbd",
      //   500: "#9e9e9e",
      //   600: "#757575",
      //   700: "#616161",
      //   800: "#424242",
      //   900: "#212121",
      //   A100: "#d5d5d5",
      //   A200: "#aaaaaa",
      //   A400: "#303030",
      //   A700: "#616161"
      // },
      // text: {
      //   primary: "rgba(0, 0, 0, 0.87)",
      //   secondary: "rgba(0, 0, 0, 0.54)",
      //   disabled: "rgba(0, 0, 0, 0.38)",
      //   hint: "rgba(0, 0, 0, 0.38)"
      // },
      // background: {
      //   paper: "#fff",
      //   default: "#fafafa"
      // },
      // action: {
      //   active: "rgba(0, 0, 0, 0.54)",
      //   hover: "rgba(0, 0, 0, 0.04)",
      //   hoverOpacity: 0.04,
      //   selected: "rgba(0, 0, 0, 0.08)",
      //   selectedOpacity: 0.08,
      //   disabled: "rgba(0, 0, 0, 0.26)",
      //   disabledBackground: "rgba(0, 0, 0, 0.12)",
      //   disabledOpacity: 0.38,
      //   focus: "rgba(0, 0, 0, 0.12)",
      //   focusOpacity: 0.12,
      //   activatedOpacity: 0.12
      // }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "& .MuiBox-root": {
            "& h1": {
              color: "#222222",
              fontSize: 32,
              textTransform: "uppercase",
              fontWeight: "bold",
            },
          },
          "& .DataGrid .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
            padding: 0,
          },
          "& .DataGrid .MuiDataGrid-root .MuiDataGrid-columnHeader, .DataGrid .MuiDataGrid-root .MuiDataGrid-cell": {
            padding: "0 20px",
          },
          "& .DataGrid .DataGridPagination": {
            color: "#707070",
          },
          "& .DataGrid .DataGridPagination p": {
            margin: 0,
          },
          "& .DataGrid .GridGeneralIcon": {
            backgroundColor: "#5D8CC1",
            color: "#ffffff",
            borderRadius: 5,
            width: 30,
            height: 30,
            padding: 4,
          },
          "& .DataGrid .GridDetailsIcon": {
            backgroundColor: "#5D8CC1",
            color: "#ffffff",
            borderRadius: 5,
            width: 30,
            height: 30,
            padding: 4,
          },
          "& .DataGrid .GridEditIcon": {
            backgroundColor: "#F5B900",
            color: "#ffffff",
            borderRadius: 5,
            width: 30,
            height: 30,
            padding: 4,
          },
          "& .DataGrid .GridDeleteIcon": {
            backgroundColor: "#E83030",
            color: "#ffffff",
            borderRadius: 5,
            width: 30,
            height: 30,
            padding: 1,
          },
          "& .DataGrid .GridProcessIcon": {
            backgroundColor: "#38761d",
            color: "#ffffff",
            borderRadius: 5,
            width: 30,
            height: 30,
            padding: 1,
          },
          "& .DataGrid .GridCloseIcon": {
            backgroundColor: "#E83030",
            color: "#ffffff",
            borderRadius: 5,
            width: 30,
            height: 30,
            padding: 5,
          },
          "& .DataGrid .GridActivateIcon": {
            backgroundColor: "#00a34f",
            color: "#ffffff",
            borderRadius: 5,
            width: 30,
            height: 30,
            padding: 1,
          },
          "& .DataGrid .ConfirmIcon": {
            backgroundColor: "#00cc00",
            color: "#ffffff",
            borderRadius: 5,
            width: 30,
            height: 30,
            padding: 1,
          },
          "& .DataGrid .GridActionButton": {
            fontSize: 22,
            textTransform: "none",
            height: 60,
            borderRadius: 8,
            boxShadow: "none",
          },
          "& .DataGrid .MuiButton-startIcon .AddIcon": {
            fontSize: 31,
          },
          "& .DataGrid .MuiButton-startIcon .UploadIcon": {
            fontSize: 31,
          },

          "& .MuiDataGrid-filterForm .MuiFormControl-root:nth-of-type(2)": {
            display: "none !important",
          },

          "*::-webkit-scrollbar": {
            width: "10px",
          },
          "*::-webkit-scrollbar-track": {
            background: "#fff",
          },
          "*::-webkit-scrollbar-thumb": {
            background: "#ddd",
            borderRadius: "5px",
          },
        },
      },
    },
  },
  gridPL
);
