import { Button, Container, Typography } from "@mui/material";
import React from "react";
import { useUserContext } from "@fenix/core/context";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { unauthorize } from "./security.api";

const Logout = () => {
  const { clearUserContext } = useUserContext();
  let navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        await unauthorize();
      } catch { }
      
      clearUserContext();
      navigate("/login");
    })();
  }, []);
  return (
    <Container component="main" maxWidth="md">
      <Typography component="h1" variant="h5">
        Zostałeś wylogowany
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Zaloguj się
      </Button>
    </Container>
  );
};

export default Logout;
