import { axiosClient } from "./axios";

const responseBody = (response) => response.data;

function clean(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export const getData = async (url, params) => {
  const response = await axiosClient
    .get(url, {
      params: params,
    })
    .then(responseBody);

  return response;
};

export const putData = async (url, data) => {
  const response = await axiosClient.put(url, clean(data)).then(responseBody);

  return response;
};

export const postData = async (url, data) => {
  const response = await axiosClient.post(url, clean(data)).then(responseBody);

  return response;
};

export const postDataWithFullResponse = async (url, data, responseType = "json") => {
  const response = await axiosClient.post(url, clean(data), { responseType: responseType });

  return response;
};

export const patchData = async (url, data, params) => {
  const response = await axiosClient
    .patch(url, clean(data), {
      params: { ...params },
    })
    .then(responseBody);

  return response;
};

export const deleteData = async (url) => {
  const response = await axiosClient.delete(url).then(responseBody);

  return response;
};
