import React, { useCallback } from "react";

import Typography from "@mui/material/Typography";

import { DataView, stringColumn } from "@fenix/core/controls/DataView/DataView";
import { GridActions, RowActions } from "@fenix/core/controls/DataView/Actions";
import { AddEditUser } from "./AddEditUser";

import { getUsers, deleteUser } from "./users.api";

const Users = ({ clearUserContext }) => {
  const deleteUserItem = useCallback(async (item) => {
    await deleteUser(item.id);
  }, []);

  return (
    <DataView
      title="PRACOWNICY"
      columns={[
        { field: "id", hide: true, filterable: false },
        { field: "login", headerName: "Email", ...stringColumn, flex: 1.5 },
        { field: "firstName", headerName: "Imię", ...stringColumn, flex: 1 },
        { field: "lastName", headerName: "Nazwisko", ...stringColumn, flex: 1.5 },
        {
          field: "groups",
          headerName: "Grupy uprawnień",
          ...stringColumn,
          sortable: false,
          renderCell: value => (
            <Typography sx={{ whiteSpace: "pre-line", fontSize: 14 }}>{value.row.groups}</Typography>
          ),
          width: 300
        }
      ]}
      actions={[
        {
          type: "rowMulti",
          flex: 1,
          label: "Akcje",
          actions: [
            // RowActions.PreviewDetails((arg) => <AddEditUser {...arg} readOnly={true} />, 'Dashboard/User/Details'),
            RowActions.Edit('Edycja użytkownika', (arg) => <AddEditUser {...arg} />, 'Dashboard/User/Edit'),
            RowActions.Delete('Usunięcie danych użytkownika', (item) => `Czy na pewno chcesz usunąć użytkownika '${item.firstName}'?`, deleteUserItem, 'Dashboard/User/Delete')
          ]
        },
        GridActions.Add('Dodaj użytkownika', 'Dodanie nowego użytkownika', (arg) => <AddEditUser {...arg} />, 'Dashboard/User/Add')
      ]}
      loadData={(filter, sort, limit) => getUsers(filter, sort, limit)}
    ></DataView>
  );
};

export default Users;
