import React, { Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Divider, Stack, AppBar, Toolbar, Typography, IconButton, CssBaseline } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LensBlurIcon from "@mui/icons-material/LensBlur";
import { grey } from "@mui/material/colors";

import { useUserContext } from "@fenix/core/context";
import Loader from "@fenix/core/components/Loader/Loader";
import { useStyles } from "./useStyles";
import Menu from "../components/Menu/Menu";
import PasswordIcon from '@mui/icons-material/Password';

import { getAppVersion, getEnvironmentName } from "@fenix/core/helpers/tools";

const Header = ({ showMenu, handleDrawerOpen }) => {
  const { userContext, clearUserContext } = useUserContext();
  const backUrl = userContext?.backUrl;
  const classes = useStyles();
  let navigate = useNavigate();

    const [homeUrl] = useState(backUrl ? backUrl : '/');
    const [changePwdUrl] = useState(backUrl ? backUrl + 'security/password-change' : '/security/password-change')
    
    const onHome = () => {
        if (backUrl) {
            window.open(backUrl, "_self");
        } else navigate("/");
    };

  const onLogout = () => {
    if (backUrl) {
      clearUserContext();
      window.open(backUrl + "logout", "_self");
    } else navigate("/logout");
  };

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                {
                    showMenu ?
                        <IconButton
                            edge="start"
                            sx={{
                                marginRight: 2,
                                marginLeft: -3
                            }}
                            id="menu-drawerOpen-btn"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                        : <LensBlurIcon sx={{ mr: "12px" }} />
                }
                <Typography variant="h5">Fenix</Typography>
                <Typography variant="h5" sx={{ m: "8px" }}>
                    -
                </Typography>
                <Typography variant="h5">{`${import.meta.env.VITE_APP_NAME}`}</Typography>
                <>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { md: "flex" } }}>
                        {userContext && (
                            <Typography
                                sx={{ m: "auto" }}
                            >{`${userContext.user?.firstName} ${userContext?.user?.lastName}`}</Typography>
                        )}
                        <Divider orientation="vertical" variant="middle" flexItem sx={{ m: "0px 10px" }} />
                        <IconButton href={changePwdUrl}>
                            <PasswordIcon sx={{ color: grey[100] }}/>
                        </IconButton>
                        <IconButton href={homeUrl}>
                            <HomeOutlinedIcon sx={{ color: grey[100] }} />
                        </IconButton>
                        {userContext && (
                            <IconButton onClick={onLogout}>
                                <PowerSettingsNewIcon sx={{ color: grey[100] }} />
                            </IconButton>
                        )}
                    </Box>
                </>
            </Toolbar>
        </AppBar>
    );
};

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Typography variant="caption" sx={{ flexGrow: 1 }}>{`${
        import.meta.env.VITE_APP_NAME
      } - ${getAppVersion()} | ${getEnvironmentName()} | `}</Typography>
      <Typography variant="caption">Vector Software 2023</Typography>
    </footer>
  );
};

const authorizedNavigation = (navigation, permissions) =>
    navigation
        .map(navigationGroup => ({
            ...navigationGroup,
            items: navigationGroup.items
                .filter(item => {
                    return !item.permission || permissions?.includes(item.permission);
                })
                .map(item => ({
                    ...item
                }))
        }))
        .filter(navigationGroup => navigationGroup.items.length > 0);

export const Layout = (props) => {
  const [open, setOpen] = React.useState(props.showMenu);

  const { userContext } = useUserContext();

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <CssBaseline />
      <Suspense fallback={<Loader />}>
        <div className={props.showMenu && open === true ? classes.rootShift : classes.root}>
          <div className={classes.root2}>
            <Header showMenu={props.showMenu} handleDrawerOpen={handleDrawerOpen} />
            {props.showMenu && (
              <Menu
                position="fixed"
                navigation={authorizedNavigation(props.navigation, userContext?.user?.permissions)}
                onToolbarClick={(isOpen) => setOpen(!isOpen)}
                isDrawerOpen={open}
              />
            )}
          </div>
          <Box component="main" className={classes.content}>
            {props.children}
          </Box>
          <Footer className={classes.footer} />
        </div>
      </Suspense>
    </>
  );
};
