import React, { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate, Navigate, Outlet } from "react-router-dom";

import NotFound from "@fenix/core/components/NotFound/NotFound";
import Dashboard from "../components/Dashboard/Dashboard";
import Users from "../components/Users/Users";
import Login from "../components/Security/Login";
import Logout from "../components/Security/Logout";
import ActivateAccount from "../components/Security/ActivateAccount";

import PasswordResetInit from "../components/Security/PasswordResetInit";
import PasswordResetProceed from "../components/Security/PasswordResetProceed";
import PasswordChange from "../components/Security/PasswordChange";

import { useUserContext } from "@fenix/core/context";
import { setAxiosClientInterceptor } from "@fenix/core/services/axios";
import { useAPIError } from "@fenix/core/components/APIErrorNotification/APIErrorProvider";


function FenixRoutes(props) {
  const { userContext } = useUserContext();
  const loginRedirect = props.authorized && !userContext;

  return loginRedirect ? (<Navigate to="/login" />) : (<Outlet />);
}


const App = () => {
  let navigate = useNavigate();
  const { setUserContext } = useUserContext();
  const { addError } = useAPIError();

  const onUnauthorizeError = () => {
    setUserContext(null);
    navigate("/");
  };

  useEffect(() => {
    setAxiosClientInterceptor(addError, onUnauthorizeError, navigate, false);
    // eslint-disable-next-line
  }, [addError, navigate]);

  return (
    <Routes>
      <Route element={<FenixRoutes authorized={true} />}>
        <Route path="/" element={<Dashboard/>} />
        <Route path="/security/password-change" element={<PasswordChange/>} />
        <Route path="/users" element={<Users />} />
        <Route path="/logout" element={<Logout />} />  
      </Route>
      <Route path="/login" element={<Login/>} />
      <Route path="/security/activate-account" element={<ActivateAccount />} />
      <Route path="/security/reset-init" element={<PasswordResetInit />} />
      <Route path="/security/reset-proceed" element={<PasswordResetProceed />} />
      <Route path="*" element={<NotFound/>} />
    </Routes>
  );
};

export default App;
