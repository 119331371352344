import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

import { useSnackbar } from "notistack";
import queryString from "query-string";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, Container, Grid, Typography } from "@mui/material";

import { FormPasswordField } from "@fenix/core/controls/FormTypes";
import { onInvalidForm } from "@fenix/core/helpers/tools";
import Meta from "@fenix/core/components/Meta/Meta";
import { resetPasswordConfirm } from "./security.api";

import useStyles from "./style";

const PasswordResetProceed = (props) => {
  const classes = useStyles();
  let location = useLocation();
  const [loading, setLoading] = React.useState(false);
  let navigate = useNavigate();
  const [queryParameters] = useState(queryString.parse(location.search));

  const form = useForm({ shouldFocusError: true });
  const { handleSubmit, getValues } = form;
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const result = await resetPasswordConfirm(data.password1, queryParameters.userId, queryParameters.code);
      if (result.error === undefined) {
        enqueueSnackbar("Hasło zostało ustawione. Teraz możesz się zalogować", { variant: "success" });
        navigate("/");
      }
    } catch (ex) {
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className={classes.paper} component="main" maxWidth="md">
      <Meta subTitle={"Zmień hasło"}></Meta>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography className={classes.title} component="h1" variant="h5">
        {"Zmiana hasła"}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit, (t) => onInvalidForm(t))} noValidate>
        <Grid container direction="column" spacing={2}>
          <FormPasswordField
            form={form}
            label={"Hasło"}
            required={true}
            name={"password1"}
            rules={{
              required: "Podaj hasło",
            }}
            key={"password1"}
          />
          <FormPasswordField
            form={form}
            label={"Powtórz hasło"}
            required={true}
            name={"password2"}
            rules={{
              required: "Podaj powtórnie hasło",
              validate: (value) => value === getValues("password1") || "Hasła muszą być takie same",
            }}
            key={"password2"}
          />
        </Grid>
        <LoadingButton
          loading={loading}
          // loadingPosition="start"
          id="passwordResetProceed-submit-btn"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {"Zmień"}
        </LoadingButton>
      </form>
    </Container>
  );
};

export default PasswordResetProceed;
